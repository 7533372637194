.MuiDataGrid-root {
    block-size: unset!important;
    margin: auto!important;
}

.MuiDataGrid-virtualScroller {
    margin-top: 0 !important;
}

.MuiDataGrid-columnHeaders {
    position: sticky!important;
    z-index: 1000!important;
}

.GridUI {
    block-size: unset!important
}

.ManageSectionTitle {
    font-size: 16px!important;
}

body{
    font-size: 36px;
}

.loader {
    width: unset!important;
    position: absolute;
    top:50%;
    left:50%;
}

.orderComplete {
    opacity:0.3;
}

.orderDelay {
    background-color: #FFC7CF;
}

.orderApproved {
    background-color: #ffedc7;
}

.badgeComplete {
    padding:3px 5px 3px 5px; 
    background-color:#ecffe0; 
    color:#94bf79;
    border: 1px solid #94bf79;
    font-size: 12px;
}

.badgeProcessing {
    padding:3px 5px 3px 5px; 
    background-color:#e6f7ff; 
    color:#1890FF;
    border: 1px solid #1890FF;
    font-size: 12px;
}

.badgeCanceled {
    padding:3px 5px 3px 5px; 
    background-color:#EBEBEB; 
    color:#A4A6A8;
    border: 1px solid #A4A6A8;
    font-size: 12px;
}

.headerLogo {
    max-height: 36px!important;
}

.containerHeader {
    max-width: 90%!important;
    margin: auto!important;
}

.containerContent {
    max-width: 90%!important;
    margin: auto!important;
}

.containerNavigation {
    max-width: 90%!important;
    margin: auto!important;
}

.MuiDataGrid-columnHeaders {
    background-color: #ebebeb!important;
}

#alertContainerMain {
    padding-top: 15px;
    padding-bottom: 15px;
}

.datePickerGroup {
    float: right;
    display: table-cell!important;
}