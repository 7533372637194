.Login {
    width: 320px;
    padding: 5% 0 0;
    margin: auto;
}

#logo-login-form {
    margin-bottom: 80px;
}

#logo-login-form-img {
    max-width: 320px;
}

#login-username {
    text-align: center;
}

#login-password {
    text-align: center;
    margin-top: 20px;
}

#login-submit {
    text-align: center;
    margin-top: 20px;
}

.MuiAlert-root {
    width:auto;
    margin-bottom: 20px;
}

#message-status {
    text-align: center;
}